import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '../HomePage.vue';

const privacyLink = "https://firebasestorage.googleapis.com/v0/b/cool-fonts-543aa.appspot.com/o/docs%2Fprivacy.pdf?alt=media";
const tosLink = "https://firebasestorage.googleapis.com/v0/b/cool-fonts-543aa.appspot.com/o/docs%2Ftos.pdf?alt=media";

Vue.use(VueRouter);

export default new VueRouter({
    mode: 'history',
    routes: [
        {
            name: 'home',
            path: '',
            component: Home,
        },
        {
            name: 'privacy',
            path: '/privacy',
            beforeEnter() {
                window.location = privacyLink
            },
        },
        {
            name: 'terms',
            path: '/terms',
            beforeEnter() {
                window.location = tosLink
            },
        },
        {
            path: '*',
            redirect: { name: 'home' }
        }
    ]
});
