import Vue from 'vue';

import App from './App.vue';
import Home from './HomePage.vue';

import router from './plugins/router';
import vuetify from './plugins/vuetify';

import detectOS from './device';
import { appStoreLink } from './links';

const deviceOS = detectOS();

if (deviceOS == "iOS") {
  window.location.href = appStoreLink;
}

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  render: h => h(App),
  components: { App, Home },
}).$mount('#app');
