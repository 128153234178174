<template>
  <v-container>
    <div>
      <v-img
        height="100px"
        width="75px"
        contain
        :src="require('../assets/letter_c.png')"
        class="letter-c"
      />
      <v-img
        height="100px"
        width="75px"
        contain
        :src="require('../assets/letter_z.png')"
        class="letter-z"
      />
      <v-img
        height="100px"
        width="75px"
        contain
        :src="require('../assets/letter_v.png')"
        class="letter-v"
      />
      <v-img
        height="100px"
        width="98px"
        contain
        :src="require('../assets/letter_star.png')"
        class="letter-star"
      />
    </div>

    <v-footer app color="#F4F4F4" height="60px" width="100%" elevation="0">
      <v-spacer></v-spacer>
      <div>
        <a class="footer-link" :href="downloadUrl" style>Download</a>
        <router-link class="footer-link" :to="'terms'" replace>Terms of Service</router-link>
        <router-link class="footer-link" :to="'privacy'" replace>Privacy Policy</router-link>
      </div>
    </v-footer>
  </v-container>
</template>

<style lang="scss" scopped>
$sm-breakpoint: 340px;
$md-breakpoint: 615px;
$lg-breakpoint: 960px;
$xl-breakpoint: 1264px;

.footer-link:link,
.footer-link:visited {
  text-decoration: none;

  padding-right: 30px;
  padding-left: 20px;

  @media only screen and (max-width: $md-breakpoint) {
    padding-right: 5px;
    padding-left: 5px;
  }

  color: rgb(145, 145, 145);
  font-size: 0.9em;
}

.letter-c {
  bottom: -30px;

  position: fixed;
  z-index: 4;
  @media only screen and (max-width: $md-breakpoint) {
    visibility: hidden;
  }
}

.letter-z {
  left: 27%;
  bottom: 5px;

  position: fixed;
  z-index: 4;
  @media only screen and (max-width: $md-breakpoint) {
    visibility: hidden;
  }
}

.letter-v {
  right: 16.5%;
  bottom: 60px + 30px;

  position: fixed;
  z-index: 4;
  @media only screen and (max-width: $md-breakpoint) {
    visibility: hidden;
  }
}

.letter-star {
  right: 37%;
  bottom: 10px;

  position: fixed;
  z-index: 4;
  @media only screen and (max-width: $lg-breakpoint - 1) {
    visibility: hidden;
  }
}
</style>

<script>
import { appStoreLink } from "@/links";

export default {
  name: "Footer",

  data: () => ({
    downloadUrl: appStoreLink
  })
};
</script>
