<template>
  <v-content>
    <Home />

    <Footer class="pa-0" />
  </v-content>
</template>

<script>
import Home from "./components/Home";
import Footer from "./components/Footer";

export default {
  name: "App",

  components: {
    Home,
    Footer
  },

  data: () => ({
    //
  })
};
</script>
