<template>
  <v-container>
    <v-layout text-center wrap>
      <v-row class="mb-6" align-content="center" justify="center">
        <v-col class="col screenshot-column" lg="4" offset-lg="1" align-self="center" order="1">
          <v-img :src="require('../assets/app-screenshot.jpg')" contain height="730"></v-img>
        </v-col>

        <v-col class="col infos-column" xs="2" align-self="center" order="2">
          <v-row align-content="center" justify="center">
            <v-col justify="center">
              <v-img
                class="app-logo"
                :src="require('../assets/app-logo.svg')"
                contain
                width="145"
                height="145"
              ></v-img>

              <h1 class="app-title">
                Fonts
                <br />Keyboard.
              </h1>

              <p class="app-subtitle">{{ subtitle }}</p>

              <a :href="appstoreUrl">
                <v-img
                  class="app-logo"
                  :src="require('../assets/app-store-badge.svg')"
                  contain
                  width="180"
                  height="60"
                />
              </a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<style lang="scss" scopped>
$sm-breakpoint: 340px;
$md-breakpoint: 615px;
$lg-breakpoint: 960px;
$xl-breakpoint: 1264px;

.app-logo {
  margin-bottom: 20px;
  @media only screen and (max-width: $md-breakpoint) {
    display: block;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-content: center;
  }
}

.app-title {
  text-align: left;
  @media only screen and (max-width: $md-breakpoint) {
    text-align: center;
  }

  line-height: 48px;
  font-size: 48px;
}

.app-subtitle {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 25px;
  @media only screen and (max-width: $md-breakpoint) {
    text-align: center;
  }

  font-size: 23px;
  color: rgb(125, 125, 125);
}

.infos-column {
  border-style: none;
}

.screenshot-column {
  border-style: none;
}
</style>

<script>
import { appStoreLink } from "@/links";

export default {
  name: "Home",

  data: () => ({
    appstoreUrl: appStoreLink,
    subtitle: "Fonts Keyboard works with every app!"
  })
};
</script>
